// https://testing-core.deploy.kuarasoftware.es/

export const API = {
	configurations: {
    create: `/configuration`,
    edit: `/configuration/`,
    delete: `/configuration/`,
    list: `/configuration`,
  },
	works: {
    create: `/work`,
    edit: `/work/`,
    delete: `/work/`,
    list: `/work`,
  },
	maintenances: {
    create: `/maintenance`,
    edit: `/maintenance/`,
    delete: `/maintenance/`,
    list: `/maintenance`,
  },
	locations: {
    create: `/location`,
    edit: `/location/`,
    delete: `/location/`,
    list: `/location`,
  },
	instruments: {
    create: `/instrument`,
    edit: `/instrument/`,
    delete: `/instrument/`,
    list: `/instrument`,
  },
	components: {
    create: `/component`,
    edit: `/component/`,
    delete: `/component/`,
    list: `/component`,
  },
	machines: {
    create: `/machine`,
    edit: `/machine/`,
    delete: `/machine/`,
    list: `/machine`,
  },
	equipments: {
    create: `/equipment`,
    edit: `/equipment/`,
    delete: `/equipment/`,
    list: `/equipment`,
  },
  auth: {
    login: `/user/login`,
    check2FA: `/user/check2FA`,
  },
  users: {
    profile: `/user/profile`,
    create: `/user/register/`,
    edit: `/user/`,
    delete: `/user/`,
    list: `/user`,
  },
  alerts: {
    create: `/alert`,
    edit: `/alert/`,
    delete: `/alert/`,
    list: `/alert`,
  },
};
