import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_LOCATION,
    DELETE_LOCATION,
    LOCATION_FORM_TOOGLE_LOADING,
    SET_LOCATION_LIST,
    SHOW_NOTIFICATION,
    UPDATE_LOCATION,
} from "../constants";
import { formatLocations } from "./settingsActionsUtils";

/* LOCATION LIST */
export const fetchLocations = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.locations.list)
            .then((response) => {
                const locations = formatLocations(response.data);
                dispatch({
                    type: SET_LOCATION_LIST,
                    payload: keyBy(locations, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// LOCATIONS
export const createLocation = (newLocation) => {
    return async (dispatch) => {
        dispatch({ type: LOCATION_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.locations.create, newLocation)
            .then((response) => {
                const location = formatLocations(response.data);
                dispatch({ type: ADD_NEW_LOCATION, payload: location });
                dispatch({ type: LOCATION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Ubicación creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: LOCATION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateLocation = (updatedLocation) => {
    return async (dispatch) => {
        dispatch({ type: LOCATION_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.locations.edit}${updatedLocation && updatedLocation._id}`, updatedLocation)
            .then((response) => {
                const location = formatLocations(response.data);
                dispatch({ type: UPDATE_LOCATION, payload: location });
                dispatch({ type: LOCATION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Ubicación actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: LOCATION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteLocations = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: LOCATION_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.locations.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_LOCATION, payload: Ids });
                dispatch({ type: LOCATION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Ubicación eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: LOCATION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
