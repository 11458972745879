import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_CONFIGURATION,
    DELETE_CONFIGURATION,
    CONFIGURATION_FORM_TOOGLE_LOADING,
    SET_CONFIGURATION_LIST,
    SHOW_NOTIFICATION,
    UPDATE_CONFIGURATION,
} from "../constants";
import { formatConfigurations } from "./settingsActionsUtils";

/* CONFIGURATION LIST */
export const fetchConfigurations = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.configurations.list)
            .then((response) => {
                const configurations = formatConfigurations(response.data);
                dispatch({
                    type: SET_CONFIGURATION_LIST,
                    payload: keyBy(configurations, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// CONFIGURATIONS
export const createConfiguration = (newConfiguration) => {
    return async (dispatch) => {
        dispatch({ type: CONFIGURATION_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.configurations.create, newConfiguration)
            .then((response) => {
                const configuration = formatConfigurations(response.data);
                dispatch({ type: ADD_NEW_CONFIGURATION, payload: configuration });
                dispatch({ type: CONFIGURATION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Configuración creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: CONFIGURATION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateConfiguration = (updatedConfiguration) => {
    return async (dispatch) => {
        dispatch({ type: CONFIGURATION_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.configurations.edit}${updatedConfiguration && updatedConfiguration._id}`, updatedConfiguration)
            .then((response) => {
                const configuration = formatConfigurations(response.data);
                dispatch({ type: UPDATE_CONFIGURATION, payload: configuration });
                dispatch({ type: CONFIGURATION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Configuración actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: CONFIGURATION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteConfigurations = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: CONFIGURATION_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.configurations.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_CONFIGURATION, payload: Ids });
                dispatch({ type: CONFIGURATION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Configuración eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: CONFIGURATION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
