/* BASIC APP CONSTANTS */
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const APP_COOKIE_NAME = "APP_COOKIE_NAME";
export const USER_COOKIE_NAME = "USER_COOKIE_NAME";
export const PASS_COOKIE_NAME = "PASS_COOKIE_NAME";
export const USER_ID_COOKIE_NAME = "USER_ID_COOKIE_NAME";
export const SET_CURRENT_ROUTE = "SET_CURRENT_ROUTE";
export const SET_SIDE_MENU_STATE = "SET_SIDE_MENU_STATE";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
export const SHOW_ACTION_MENU = "SHOW_ACTION_MENU";
export const HIDE_ACTION_MENU = "HIDE_ACTION_MENU";
export const SHOW_BOTTOM_TOOLBAR = "SHOW_BOTTOM_TOOLBAR";
export const HIDE_BOTTOM_TOOLBAR = "HIDE_BOTTOM_TOOLBAR";
export const SET_USER_LIST = "SET_USER_LIST";
export const EDIT_DISABLED_BUTTONS = "EDIT_DISABLED_BUTTONS";
export const SET_LOGS_LIST = "SET_LOGS_LIST";
export const THEME = "THEME";

/* <---- BASIC APP CONSTANTS ----> */

/* SOCKET CONSTANTS */
export const CONNECTED = "connected";
export const DISCONNECTED = "disconnected";
/* <---- SOCKET CONSTANTS ----> */

/* CONFIGURATION */
export const SET_CONFIGURATION = "SET_CONFIGURATION";
/* <---- CONFIGURATION ----> */

/* USER */
export const SET_PROFILE = "SET_PROFILE";
/* <---- USER ----> */

/* USERS */
export const ADD_NEW_USER = "ADD_NEW_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const OPEN_USERS_FORM = "OPEN_USERS_FORM";
export const CLOSE_USERS_FORM = "CLOSE_USERS_FORM";
export const EDIT_SELECTED_USER = "EDIT_SELECTED_USER";
export const USERS_FORM_TOOGLE_LOADING = "USERS_FORM_TOOGLE_LOADING";
/* <---- USERS ----> */

/* CONFIGURATION */
export const SET_CONFIGURATION_LIST = "SET_CONFIGURATION_LIST";
export const ADD_NEW_CONFIGURATION = "ADD_NEW_CONFIGURATION";
export const UPDATE_CONFIGURATION = "UPDATE_CONFIGURATION";
export const DELETE_CONFIGURATION = "DELETE_CONFIGURATION";
export const OPEN_CONFIGURATION_FORM = "OPEN_CONFIGURATION_FORM";
export const CLOSE_CONFIGURATION_FORM = "CLOSE_CONFIGURATION_FORM";
export const EDIT_SELECTED_CONFIGURATION = "EDIT_SELECTED_CONFIGURATION";
export const CONFIGURATION_FORM_TOOGLE_LOADING = "CONFIGURATION_FORM_TOOGLE_LOADING";
/* <---- CONFIGURATION ----> */

/* WORK */
export const SET_WORK_LIST = "SET_WORK_LIST";
export const ADD_NEW_WORK = "ADD_NEW_WORK";
export const UPDATE_WORK = "UPDATE_WORK";
export const DELETE_WORK = "DELETE_WORK";
export const OPEN_WORK_FORM = "OPEN_WORK_FORM";
export const CLOSE_WORK_FORM = "CLOSE_WORK_FORM";
export const EDIT_SELECTED_WORK = "EDIT_SELECTED_WORK";
export const WORK_FORM_TOOGLE_LOADING = "WORK_FORM_TOOGLE_LOADING";
/* <---- WORK ----> */

/* MAINTENANCE */
export const SET_MAINTENANCE_LIST = "SET_MAINTENANCE_LIST";
export const ADD_NEW_MAINTENANCE = "ADD_NEW_MAINTENANCE";
export const UPDATE_MAINTENANCE = "UPDATE_MAINTENANCE";
export const DELETE_MAINTENANCE = "DELETE_MAINTENANCE";
export const OPEN_MAINTENANCE_FORM = "OPEN_MAINTENANCE_FORM";
export const CLOSE_MAINTENANCE_FORM = "CLOSE_MAINTENANCE_FORM";
export const EDIT_SELECTED_MAINTENANCE = "EDIT_SELECTED_MAINTENANCE";
export const MAINTENANCE_FORM_TOOGLE_LOADING = "MAINTENANCE_FORM_TOOGLE_LOADING";
/* <---- MAINTENANCE ----> */

/* LOCATION */
export const SET_LOCATION_LIST = "SET_LOCATION_LIST";
export const ADD_NEW_LOCATION = "ADD_NEW_LOCATION";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const DELETE_LOCATION = "DELETE_LOCATION";
export const OPEN_LOCATION_FORM = "OPEN_LOCATION_FORM";
export const CLOSE_LOCATION_FORM = "CLOSE_LOCATION_FORM";
export const EDIT_SELECTED_LOCATION = "EDIT_SELECTED_LOCATION";
export const LOCATION_FORM_TOOGLE_LOADING = "LOCATION_FORM_TOOGLE_LOADING";
/* <---- LOCATION ----> */

/* INSTRUMENT */
export const SET_INSTRUMENT_LIST = "SET_INSTRUMENT_LIST";
export const ADD_NEW_INSTRUMENT = "ADD_NEW_INSTRUMENT";
export const UPDATE_INSTRUMENT = "UPDATE_INSTRUMENT";
export const DELETE_INSTRUMENT = "DELETE_INSTRUMENT";
export const OPEN_INSTRUMENT_FORM = "OPEN_INSTRUMENT_FORM";
export const CLOSE_INSTRUMENT_FORM = "CLOSE_INSTRUMENT_FORM";
export const EDIT_SELECTED_INSTRUMENT = "EDIT_SELECTED_INSTRUMENT";
export const INSTRUMENT_FORM_TOOGLE_LOADING = "INSTRUMENT_FORM_TOOGLE_LOADING";
/* <---- INSTRUMENT ----> */

/* COMPONENT */
export const SET_COMPONENT_LIST = "SET_COMPONENT_LIST";
export const ADD_NEW_COMPONENT = "ADD_NEW_COMPONENT";
export const UPDATE_COMPONENT = "UPDATE_COMPONENT";
export const DELETE_COMPONENT = "DELETE_COMPONENT";
export const OPEN_COMPONENT_FORM = "OPEN_COMPONENT_FORM";
export const CLOSE_COMPONENT_FORM = "CLOSE_COMPONENT_FORM";
export const EDIT_SELECTED_COMPONENT = "EDIT_SELECTED_COMPONENT";
export const COMPONENT_FORM_TOOGLE_LOADING = "COMPONENT_FORM_TOOGLE_LOADING";
/* <---- COMPONENT ----> */

/* MACHINE */
export const SET_MACHINE_LIST = "SET_MACHINE_LIST";
export const ADD_NEW_MACHINE = "ADD_NEW_MACHINE";
export const UPDATE_MACHINE = "UPDATE_MACHINE";
export const DELETE_MACHINE = "DELETE_MACHINE";
export const OPEN_MACHINE_FORM = "OPEN_MACHINE_FORM";
export const CLOSE_MACHINE_FORM = "CLOSE_MACHINE_FORM";
export const EDIT_SELECTED_MACHINE = "EDIT_SELECTED_MACHINE";
export const MACHINE_FORM_TOOGLE_LOADING = "MACHINE_FORM_TOOGLE_LOADING";
/* <---- MACHINE ----> */

/* EQUIPMENT */
export const SET_EQUIPMENT_LIST = "SET_EQUIPMENT_LIST";
export const ADD_NEW_EQUIPMENT = "ADD_NEW_EQUIPMENT";
export const UPDATE_EQUIPMENT = "UPDATE_EQUIPMENT";
export const DELETE_EQUIPMENT = "DELETE_EQUIPMENT";
export const OPEN_EQUIPMENT_FORM = "OPEN_EQUIPMENT_FORM";
export const CLOSE_EQUIPMENT_FORM = "CLOSE_EQUIPMENT_FORM";
export const EDIT_SELECTED_EQUIPMENT = "EDIT_SELECTED_EQUIPMENT";
export const EQUIPMENT_FORM_TOOGLE_LOADING = "EQUIPMENT_FORM_TOOGLE_LOADING";
/* <---- EQUIPMENT ----> */

/* ROUTES */
export const SET_ALL_ROUTES = "SET_ALL_ROUTES";
export const UPDATE_ROUTE = "UPDATE_ROUTE";
/* <---- ROUTES ----> */

/* ALERT */
export const SET_ALERT_LIST = "SET_ALERT_LIST";
export const ADD_NEW_ALERT = "ADD_NEW_ALERT";
export const UPDATE_ALERT = "UPDATE_ALERT";
export const DELETE_ALERT = "DELETE_ALERT";
export const OPEN_ALERT_FORM = "OPEN_ALERT_FORM";
export const CLOSE_ALERT_FORM = "CLOSE_ALERT_FORM";
export const EDIT_SELECTED_ALERT = "EDIT_SELECTED_ALERT";
export const ALERT_FORM_TOOGLE_LOADING = "ALERT_FORM_TOOGLE_LOADING";
/* <---- ALERT ----> */
