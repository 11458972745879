import moment from "moment";
import { isArray, isObject } from "lodash";

export const formatConfigurations = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.description}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.description}`,
    };
  }
};
export const formatWorks = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.description}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.description}`,
    };
  }
};
export const formatMaintenances = (data, state) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${state && state.maintenances && state.instruments && state.machines && state.equipments && state.components && state.locations && (
        (data.maintenance && state.maintenances[data.maintenance] && state.maintenances[data.maintenance].label) ||
        (data.instrument && state.instruments[data.instrument] && state.instruments[data.instrument].label) ||
        (data.machine && state.machines[data.machine] && state.machines[data.machine].label) ||
        (data.equipment && state.equipments[data.equipment] && state.equipments[data.equipment].label) ||
        (data.component && state.components[data.component] && state.components[data.component].label) ||
        (data.location && state.locations[data.location] && state.locations[data.location].label)
      )
        }`,
      value: data._id,
      date: data.date && moment(data.date),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.description}`,
      date: data.date && moment(data.date),
    };
  }
};
export const formatLocations = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
      dischargeDate: data.dischargeDate && moment(data.dischargeDate),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
      dischargeDate: data.dischargeDate && moment(data.dischargeDate),
    };
  }
};
export const formatInstruments = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
      dischargeDate: data.dischargeDate && moment(data.dischargeDate),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
      dischargeDate: data.dischargeDate && moment(data.dischargeDate),
    };
  }
};
export const formatComponents = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
      dischargeDate: data.dischargeDate && moment(data.dischargeDate),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
      dischargeDate: data.dischargeDate && moment(data.dischargeDate),
    };
  }
};
export const formatMachines = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
      dischargeDate: data.dischargeDate && moment(data.dischargeDate),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
      dischargeDate: data.dischargeDate && moment(data.dischargeDate),
    };
  }
};
export const formatEquipments = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
      dischargeDate: data.dischargeDate && moment(data.dischargeDate),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
      dischargeDate: data.dischargeDate && moment(data.dischargeDate),
    };
  }
};
export const formatUsers = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name} ${data.surname}`,
      value: data._id
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name} ${data.surname}`
    };
  }
};

export const formatAlerts = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.title}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.title}`,
    };
  }
};
