import { handleActions, handleAction } from "redux-actions";
import {
    AUTH,
    SET_PROFILE,
    SET_CURRENT_ROUTE,
    LOGOUT,
    SHOW_NOTIFICATION,
    CLOSE_NOTIFICATION,
    SET_SIDE_MENU_STATE,
    OPEN_USERS_FORM,
    CLOSE_USERS_FORM,
    EDIT_SELECTED_USER,
    USERS_FORM_TOOGLE_LOADING,
    SET_USER_LIST,
    ADD_NEW_USER,
    UPDATE_USER,
    DELETE_USER,
    OPEN_ALERT_FORM,
    CLOSE_ALERT_FORM,
    EDIT_SELECTED_ALERT,
    ALERT_FORM_TOOGLE_LOADING,
    SET_ALERT_LIST,
    ADD_NEW_ALERT,
    UPDATE_ALERT,
    DELETE_ALERT,
    THEME,
    ADD_NEW_EQUIPMENT,
    SET_EQUIPMENT_LIST,
    UPDATE_EQUIPMENT,
    DELETE_EQUIPMENT,
    OPEN_EQUIPMENT_FORM,
    CLOSE_EQUIPMENT_FORM,
    EDIT_SELECTED_EQUIPMENT,
    EQUIPMENT_FORM_TOOGLE_LOADING,
    ADD_NEW_MACHINE,
    SET_MACHINE_LIST,
    UPDATE_MACHINE,
    DELETE_MACHINE,
    OPEN_MACHINE_FORM,
    CLOSE_MACHINE_FORM,
    EDIT_SELECTED_MACHINE,
    MACHINE_FORM_TOOGLE_LOADING,
    ADD_NEW_COMPONENT,
    SET_COMPONENT_LIST,
    UPDATE_COMPONENT,
    DELETE_COMPONENT,
    OPEN_COMPONENT_FORM,
    CLOSE_COMPONENT_FORM,
    EDIT_SELECTED_COMPONENT,
    COMPONENT_FORM_TOOGLE_LOADING,
    ADD_NEW_INSTRUMENT,
    SET_INSTRUMENT_LIST,
    UPDATE_INSTRUMENT,
    DELETE_INSTRUMENT,
    OPEN_INSTRUMENT_FORM,
    CLOSE_INSTRUMENT_FORM,
    EDIT_SELECTED_INSTRUMENT,
    INSTRUMENT_FORM_TOOGLE_LOADING,
    ADD_NEW_LOCATION,
    SET_LOCATION_LIST,
    UPDATE_LOCATION,
    DELETE_LOCATION,
    OPEN_LOCATION_FORM,
    CLOSE_LOCATION_FORM,
    EDIT_SELECTED_LOCATION,
    LOCATION_FORM_TOOGLE_LOADING,
    ADD_NEW_MAINTENANCE,
    SET_MAINTENANCE_LIST,
    UPDATE_MAINTENANCE,
    DELETE_MAINTENANCE,
    OPEN_MAINTENANCE_FORM,
    CLOSE_MAINTENANCE_FORM,
    EDIT_SELECTED_MAINTENANCE,
    MAINTENANCE_FORM_TOOGLE_LOADING,
    ADD_NEW_WORK,
    SET_WORK_LIST,
    UPDATE_WORK,
    DELETE_WORK,
    OPEN_WORK_FORM,
    CLOSE_WORK_FORM,
    EDIT_SELECTED_WORK,
    WORK_FORM_TOOGLE_LOADING,
    ADD_NEW_CONFIGURATION,
    SET_CONFIGURATION_LIST,
    UPDATE_CONFIGURATION,
    DELETE_CONFIGURATION,
    OPEN_CONFIGURATION_FORM,
    CLOSE_CONFIGURATION_FORM,
    EDIT_SELECTED_CONFIGURATION,
    CONFIGURATION_FORM_TOOGLE_LOADING,
} from "./../../constants";

/* BASIC */
export const authorization = handleActions(
    {
        [AUTH]: (state, action) => action.payload,
        [LOGOUT]: () => ({ auth: false }),
    },
    null
);

/* PROFILE */
export const profile = handleActions(
    {
        [SET_PROFILE]: (state, action) => action.payload,
    },
    null
);

/* APP */
export const currentRoute = handleAction(
    SET_CURRENT_ROUTE,
    (state, action) => action.payload,
    {}
);

export const notifications = handleActions(
    {
        [SHOW_NOTIFICATION]: (state, action) => action.payload,
        [CLOSE_NOTIFICATION]: (state, action) => ({
            show: false,
            status: null,
            message: null,
        }),
    },
    { show: false, status: null, message: null }
);

/* APP */
export const app = handleActions(
    {
        [SET_SIDE_MENU_STATE]: (state, action) => {
            return { ...state, isCollapsedSideMenu: action.payload };
        },
    },
    { isCollapsedSideMenu: true }
);

export const currentTheme = handleAction(
    THEME,
    (state, action) => action.payload,
    true
);

export const users = handleActions(
    {
        [SET_USER_LIST]: (state, action) => action.payload,
        [ADD_NEW_USER]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_USER]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_USER]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const usersForm = handleActions(
    {
        [OPEN_USERS_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_USERS_FORM]: () => ({ show: false, data: null, loading: false }),
        [EDIT_SELECTED_USER]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [USERS_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

export const alerts = handleActions(
    {
        [SET_ALERT_LIST]: (state, action) => action.payload,
        [ADD_NEW_ALERT]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_ALERT]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_ALERT]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const alertsForm = handleActions(
    {
        [OPEN_ALERT_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_ALERT_FORM]: () => ({ show: false, data: null, loading: false }),
        [EDIT_SELECTED_ALERT]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [ALERT_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// EQUIPMENTS
export const equipments = handleActions(
    {
        [SET_EQUIPMENT_LIST]: (state, action) => action.payload,
        [ADD_NEW_EQUIPMENT]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_EQUIPMENT]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_EQUIPMENT]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const equipmentsForm = handleActions(
    {
        [OPEN_EQUIPMENT_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_EQUIPMENT_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_EQUIPMENT]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [EQUIPMENT_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// MACHINES
export const machines = handleActions(
    {
        [SET_MACHINE_LIST]: (state, action) => action.payload,
        [ADD_NEW_MACHINE]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_MACHINE]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_MACHINE]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const machinesForm = handleActions(
    {
        [OPEN_MACHINE_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_MACHINE_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_MACHINE]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [MACHINE_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// COMPONENTS
export const components = handleActions(
    {
        [SET_COMPONENT_LIST]: (state, action) => action.payload,
        [ADD_NEW_COMPONENT]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_COMPONENT]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_COMPONENT]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const componentsForm = handleActions(
    {
        [OPEN_COMPONENT_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_COMPONENT_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_COMPONENT]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [COMPONENT_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// INSTRUMENTS
export const instruments = handleActions(
    {
        [SET_INSTRUMENT_LIST]: (state, action) => action.payload,
        [ADD_NEW_INSTRUMENT]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_INSTRUMENT]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_INSTRUMENT]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const instrumentsForm = handleActions(
    {
        [OPEN_INSTRUMENT_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_INSTRUMENT_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_INSTRUMENT]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [INSTRUMENT_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// LOCATIONS
export const locations = handleActions(
    {
        [SET_LOCATION_LIST]: (state, action) => action.payload,
        [ADD_NEW_LOCATION]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_LOCATION]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_LOCATION]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const locationsForm = handleActions(
    {
        [OPEN_LOCATION_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_LOCATION_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_LOCATION]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [LOCATION_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// MAINTENANCES
export const maintenances = handleActions(
    {
        [SET_MAINTENANCE_LIST]: (state, action) => action.payload,
        [ADD_NEW_MAINTENANCE]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_MAINTENANCE]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_MAINTENANCE]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const maintenancesForm = handleActions(
    {
        [OPEN_MAINTENANCE_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_MAINTENANCE_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_MAINTENANCE]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [MAINTENANCE_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// WORKS
export const works = handleActions(
    {
        [SET_WORK_LIST]: (state, action) => action.payload,
        [ADD_NEW_WORK]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_WORK]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_WORK]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const worksForm = handleActions(
    {
        [OPEN_WORK_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_WORK_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_WORK]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [WORK_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// CONFIGURATIONS
export const configurations = handleActions(
    {
        [SET_CONFIGURATION_LIST]: (state, action) => action.payload,
        [ADD_NEW_CONFIGURATION]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_CONFIGURATION]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_CONFIGURATION]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const configurationsForm = handleActions(
    {
        [OPEN_CONFIGURATION_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_CONFIGURATION_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_CONFIGURATION]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [CONFIGURATION_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);
