import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_MACHINE,
    DELETE_MACHINE,
    MACHINE_FORM_TOOGLE_LOADING,
    SET_MACHINE_LIST,
    SHOW_NOTIFICATION,
    UPDATE_MACHINE,
} from "../constants";
import { formatMachines } from "./settingsActionsUtils";

/* MACHINE LIST */
export const fetchMachines = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.machines.list)
            .then((response) => {
                const machines = formatMachines(response.data);
                dispatch({
                    type: SET_MACHINE_LIST,
                    payload: keyBy(machines, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// MACHINES
export const createMachine = (newMachine) => {
    return async (dispatch) => {
        dispatch({ type: MACHINE_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.machines.create, newMachine)
            .then((response) => {
                const machine = formatMachines(response.data);
                dispatch({ type: ADD_NEW_MACHINE, payload: machine });
                dispatch({ type: MACHINE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Máquina creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: MACHINE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateMachine = (updatedMachine) => {
    return async (dispatch) => {
        dispatch({ type: MACHINE_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.machines.edit}${updatedMachine && updatedMachine._id}`, updatedMachine)
            .then((response) => {
                const machine = formatMachines(response.data);
                dispatch({ type: UPDATE_MACHINE, payload: machine });
                dispatch({ type: MACHINE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Máquina actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: MACHINE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteMachines = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: MACHINE_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.machines.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_MACHINE, payload: Ids });
                dispatch({ type: MACHINE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Máquina eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: MACHINE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
