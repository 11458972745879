import { createSelector } from "reselect";
import moment from "moment";
import "moment/locale/es";
import { ROLES_RAW } from "../../constants/permissions";
moment().locale("es");

/* APP */
export const isUserAuthorized = createSelector(
  (state) => state.authorization,
  (authorization) => authorization
);
export const getCurrentRoute = createSelector(
  (state) => state.currentRoute,
  (currentRoute) => currentRoute
);
export const getCurrentRouteTitle = createSelector(
  getCurrentRoute,
  (route) => route.title
);
export const getSideMenuState = createSelector(
  (state) => state.app,
  (app) => app.isCollapsedSideMenu
);
export const getCurrentTheme = createSelector(
  (state) => state.currentTheme,
  (currentTheme) => currentTheme
);

/* PROFILE */
export const getUserProfile = createSelector(
  (state) => state.profile,
  (profile) => profile
);
export const currentUserId = createSelector(
  (state) => state.profile,
  (profile) => profile && profile.id
);
export const getCurrentUserRole = createSelector(getUserProfile, (profile) =>
  !profile ? null : (ROLES_RAW.includes(profile.role) ? profile.role : null)
);

/* NOTIFICATIONS STATE */
export const getNotificationsState = createSelector(
  (state) => state.notifications,
  (notifications) => notifications
);

/* USERS */
export const getUsers = createSelector(
  (state) => state.users,
  (users) => users
);
export const getUsersForm = createSelector(
  (state) => state.usersForm,
  (form) => form
);

/* ALERT */
export const getAlerts = createSelector(
  (state) => state.alerts,
  (alerts) => alerts
);
export const getAlertsForm = createSelector(
  (state) => state.alertsForm,
  (form) => form
);

/* EQUIPMENTS */
export const getEquipments = createSelector(
    (state) => state.equipments,
    (equipments) => equipments
);
export const getEquipmentsForm = createSelector(
    (state) => state.equipmentsForm,
    (form) => form
);

/* MACHINES */
export const getMachines = createSelector(
    (state) => state.machines,
    (machines) => machines
);
export const getMachinesForm = createSelector(
    (state) => state.machinesForm,
    (form) => form
);

/* COMPONENTS */
export const getComponents = createSelector(
    (state) => state.components,
    (components) => components
);
export const getComponentsForm = createSelector(
    (state) => state.componentsForm,
    (form) => form
);

/* INSTRUMENTS */
export const getInstruments = createSelector(
    (state) => state.instruments,
    (instruments) => instruments
);
export const getInstrumentsForm = createSelector(
    (state) => state.instrumentsForm,
    (form) => form
);

/* LOCATIONS */
export const getLocations = createSelector(
    (state) => state.locations,
    (locations) => locations
);
export const getLocationsForm = createSelector(
    (state) => state.locationsForm,
    (form) => form
);

/* MAINTENANCES */
export const getMaintenances = createSelector(
    (state) => state.maintenances,
    (maintenances) => maintenances
);
export const getMaintenancesForm = createSelector(
    (state) => state.maintenancesForm,
    (form) => form
);

/* WORKS */
export const getWorks = createSelector(
    (state) => state.works,
    (works) => works
);
export const getWorksForm = createSelector(
    (state) => state.worksForm,
    (form) => form
);

/* CONFIGURATIONS */
export const getConfigurations = createSelector(
    (state) => state.configurations,
    (configurations) => configurations
);
export const getConfigurationsForm = createSelector(
    (state) => state.configurationsForm,
    (form) => form
);
