import { ApiOutlined, AppstoreOutlined, CalendarOutlined, CompassOutlined, EnvironmentOutlined, FormOutlined, LoginOutlined, LogoutOutlined, SettingOutlined, ToolOutlined, UserOutlined } from '@ant-design/icons';
import React from 'react';
import { ROLES } from "./permissions";

export const routes = {
  home: {
    key: "home",
    slug: "home",
    to: "/home",
    title: "Calendario",
    icon: <CalendarOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },

  profile: {
    key: "profile",
    slug: "profile",
    to: "/profile",
    title: "Perfil",
    icon: <UserOutlined />,
    showInMenu: false,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },

  login: {
    key: "login",
    slug: "login",
    to: "/login",
    title: "LOGIN",
    icon: <LoginOutlined />,
    showInMenu: false,
    showHeader: false,
    showSearch: false,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },

  equipments: {
    key: "equipments",
    slug: "equipments",
    to: "/equipments",
    title: "Equipos",
    icon: <ToolOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  machines: {
    key: "machines",
    slug: "machines",
    to: "/machines",
    title: "Máquinas",
    icon: <SettingOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  components: {
    key: "components",
    slug: "components",
    to: "/components",
    title: "Componentes",
    icon: <AppstoreOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  instruments: {
    key: "instruments",
    slug: "instruments",
    to: "/instruments",
    title: "Instrumentos",
    icon: <CompassOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  locations: {
    key: "locations",
    slug: "locations",
    to: "/locations",
    title: "Ubicacións",
    icon: <EnvironmentOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  maintenances: {
    key: "maintenances",
    slug: "maintenances",
    to: "/maintenances",
    title: "Mantenimientos",
    icon: <ApiOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  works: {
    key: "works",
    slug: "works",
    to: "/works",
    title: "Parte de trabajos",
    icon: <FormOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  logout: {
    key: "logout",
    slug: "logout",
    to: "/logout",
    title: "Cerrar Sesión",
    icon: <LogoutOutlined />,
    showInMenu: false,
    showHeader: false,
    showSearch: false,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },
  users: {
    key: "users",
    slug: "users",
    to: "/users",
    title: "Usuarios",
    icon: <UserOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
};
