import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_WORK,
    DELETE_WORK,
    WORK_FORM_TOOGLE_LOADING,
    SET_WORK_LIST,
    SHOW_NOTIFICATION,
    UPDATE_WORK,
} from "../constants";
import { formatWorks } from "./settingsActionsUtils";

/* WORK LIST */
export const fetchWorks = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.works.list)
            .then((response) => {
                const works = formatWorks(response.data);
                dispatch({
                    type: SET_WORK_LIST,
                    payload: keyBy(works, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// WORKS
export const createWork = (newWork) => {
    return async (dispatch) => {
        dispatch({ type: WORK_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.works.create, newWork)
            .then((response) => {
                const work = formatWorks(response.data);
                dispatch({ type: ADD_NEW_WORK, payload: work });
                dispatch({ type: WORK_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Parte de trabajo creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: WORK_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateWork = (updatedWork) => {
    return async (dispatch) => {
        dispatch({ type: WORK_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.works.edit}${updatedWork && updatedWork._id}`, updatedWork)
            .then((response) => {
                const work = formatWorks(response.data);
                dispatch({ type: UPDATE_WORK, payload: work });
                dispatch({ type: WORK_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Parte de trabajo actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: WORK_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteWorks = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: WORK_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.works.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_WORK, payload: Ids });
                dispatch({ type: WORK_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Parte de trabajo eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: WORK_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
