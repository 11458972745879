import { combineReducers } from "redux";
import {
  authorization,
  profile,
  currentRoute,
  notifications,
  app,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
  equipments,
  equipmentsForm,
  machines,
  machinesForm,
  components,
  componentsForm,
  instruments,
  instrumentsForm,
  locations,
  locationsForm,
  maintenances,
  maintenancesForm,
  works,
  worksForm,
  configurations,
  configurationsForm,
} from "./Reducers";

export default combineReducers({
  configurations,
  configurationsForm,
  works,
  worksForm,
  maintenances,
  maintenancesForm,
  locations,
  locationsForm,
  instruments,
  instrumentsForm,
  components,
  componentsForm,
  machines,
  machinesForm,
  equipments,
  equipmentsForm,
  app,
  authorization,
  currentRoute,
  profile,
  notifications,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
});
