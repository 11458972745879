import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_INSTRUMENT,
    DELETE_INSTRUMENT,
    INSTRUMENT_FORM_TOOGLE_LOADING,
    SET_INSTRUMENT_LIST,
    SHOW_NOTIFICATION,
    UPDATE_INSTRUMENT,
} from "../constants";
import { formatInstruments } from "./settingsActionsUtils";

/* INSTRUMENT LIST */
export const fetchInstruments = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.instruments.list)
            .then((response) => {
                const instruments = formatInstruments(response.data);
                dispatch({
                    type: SET_INSTRUMENT_LIST,
                    payload: keyBy(instruments, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// INSTRUMENTS
export const createInstrument = (newInstrument) => {
    return async (dispatch) => {
        dispatch({ type: INSTRUMENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.instruments.create, newInstrument)
            .then((response) => {
                const instrument = formatInstruments(response.data);
                dispatch({ type: ADD_NEW_INSTRUMENT, payload: instrument });
                dispatch({ type: INSTRUMENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Instrumento creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: INSTRUMENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateInstrument = (updatedInstrument) => {
    return async (dispatch) => {
        dispatch({ type: INSTRUMENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.instruments.edit}${updatedInstrument && updatedInstrument._id}`, updatedInstrument)
            .then((response) => {
                const instrument = formatInstruments(response.data);
                dispatch({ type: UPDATE_INSTRUMENT, payload: instrument });
                dispatch({ type: INSTRUMENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Instrumento actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: INSTRUMENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteInstruments = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: INSTRUMENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.instruments.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_INSTRUMENT, payload: Ids });
                dispatch({ type: INSTRUMENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Instrumento eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: INSTRUMENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
